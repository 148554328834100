<template>
    <login-page>
        <v-card
            class="mx-auto no-bg"
            flat
            max-width="304"
            min-width="304">
            <div class="logo-wrapper">
                <brand-logo/>
                <div class="separation-line"/>
            </div>

            <validation-observer v-slot="{ handleSubmit }">
                <v-form
                    class="auth-form"
                    @submit.prevent="handleSubmit(submitLoginForm)">
                    <v-alert
                        v-show="showEmailConfirmationAlert"
                        class="email-confirmation-alert"
                        type="success"
                        dense
                        outlined
                        :icon="false"
                        text>
                        {{ emailConfirmationText }}
                    </v-alert>

                    <div
                        v-if="loginError"
                        class="error pa-2 mb-4 text--white">
                        {{ loginError }}
                    </div>
                    <div
                        v-if="resetStatus.success"
                        class="success pa-2 mb-4 text--white">
                        {{ resetStatus.message }}
                    </div>

                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Email"
                        rules="required|email"
                        tag="label">
                        <div class="text-body-1 mt-4 mb-2">
                            Email
                        </div>
                        <v-text-field
                            v-model="credentials.email"
                            height="34"
                            hide-details="auto"
                            outlined
                            placeholder="Email"
                            type="email"
                            class="input-field"/>
                        <div class="text-body-1 text--error mt-1">
                            {{ errors[0] }}
                        </div>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Password"
                        rules="required"
                        tag="label">
                        <div class="text-body-1 mt-4 mb-2">
                            Password
                        </div>
                        <v-text-field
                            v-model="credentials.password"
                            height="34"
                            hide-details="auto"
                            outlined
                            placeholder="Password"
                            type="password"
                            class="input-field"/>
                        <div class="text-body-1 text--error mt-1">
                            {{ errors[0] }}
                        </div>
                    </validation-provider>

                    <div
                        class="d-flex justify-space-between align-center mt-4 mb-2 flex-column flex-wrap">
                        <span class="btn-auth-wrapper">
                            <v-btn
                                block
                                :loading="loading"
                                :disabled="loadingGoogle"
                                class="siv-btn--auth font-weight-bold text-capitalize px-13"
                                color="#9FF4A9"
                                dark
                                depressed
                                height="34"
                                type="submit">
                                Sing In
                            </v-btn>
                        </span>
                        <span class="btn-auth-wrapper mt-4 mb-2">
                            <v-btn
                                block
                                :loading="loadingGoogle"
                                :disabled="loading"
                                class="text-h6 v-btn--auth text-capitalize px-13 d-inline-flex align-center"
                                color="#ffffff"
                                depressed
                                height="34"
                                @click.prevent="submitRegGoogleForm()">
                                <img
                                    :src="appGoogleLogo"
                                    width="18"
                                    height="18"
                                    class="mr-2"
                                    alt="Google logo">
                                Sign In with Google
                            </v-btn>
                        </span>
                        <router-link
                            class="auth-form__text-link text-body-1 mt-3 pl-2"
                            :to="{ name: 'forgot-password' }">
                            Forgot your password? Reset Here.
                        </router-link>
                    </div>
                </v-form>
            </validation-observer>
        </v-card>
    </login-page>
</template>

<script>
    import LoginPage from "@/views/user/login/login-page";
    import mixinAuth from "@/views/user/login/mixin-auth";
    import BrandLogo from "@/views/user/login/BrandLogo";
    import axios from "axios";

    export default {
        name: "sing-in",
        mixins: [mixinAuth],
        components: {
            LoginPage,
            BrandLogo
        },
        data: () => ({
            loading: false,
            loadingGoogle: false,
            credentials: {
                email: "",
                password: ""
            },
            loginError: "",
            showEmailConfirmationAlert: false
            // resetStatus: {
            //     success: false,
            //     message: ""
            // }
        }),
        beforeRouteEnter(to, from, next) {
            const routeHasEmailConfirmation =
                to.query.emailConfirmed == 0 || to.query.emailConfirmed == 1;

            next(vm => {
                if (routeHasEmailConfirmation) {
                    vm.showEmailConfirmationAlert = true;
                    setTimeout(() => {
                        vm.showEmailConfirmationAlert = false;
                    }, 6000);
                }
            });
        },
        methods: {
            async submitRegGoogleForm() {
                this.loadingGoogle = true
                try {
                    const response = await axios.get(
                        process.env.VUE_APP_BASE_API_URL + `/authorizations/google`
                    );

                    window.location.href = response.data.data.link;
                }catch (e) {
                    console.error(e)
                } finally {
                    this.loadingGoogle = false
                }
            },
            async submitLoginForm() {
                this.loading = true
                try {
                    const response = await this.$store.dispatch(
                        "login",
                        this.credentials
                    );
                    if (response) {
                        this.loginError = response.message;
                    }
                } catch (e) {
                    console.error(e)
                } finally {
                    this.loading = false
                }
            },
            showResetStatus(data) {
                this.resetStatus = { ...data };
            }
        },
        computed: {
            resetStatus() {
                return this.$store.getters.resetStatus;
            },
            emailConfirmationText() {
                return this.$route.query.emailConfirmed !== undefined &&
                    this.$route.query.emailConfirmed == 0
                    ? "Confirm your email address"
                    : "Success. Your E-mail has been verified successfully";
            }
        }
    };
</script>

<style lang="scss" scoped>
.auth-form {
    &__text-link {
        font-size: 13px !important;
        width: 100%;
        display: block;
        text-align: left;
    }
}
::v-deep .input-field {
    input {
        &:-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
            box-shadow: 0 0 0 16px white inset !important;
        }
    }
}

.siv-btn--auth {
    border: 1px solid #464646;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 400 !important;
    color: #464646;
    border: 1px solid #464646 !important;
}
.auth-form__text-link {
    font-size: 14px !important;
    color: #0046af;
    text-align: center;
    font-family: "Futura PT" !important;
}

.logo-wrapper {
    max-width: 112px;
    margin: 0 auto 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .separation-line {
        width: 50px;
        height: 1px;
        background-color: #464646;
    }
}
.no-bg {
    background-color: transparent !important;
    background: transparent;
}
</style>
